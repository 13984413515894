import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { MomentDate } from "./Moment";

export const ManagerC = t.type({
    id: t.number,
    displayName: t.string,
    salutation: Nullable(t.string),
    firstName: Nullable(t.string),
    lastName: Nullable(t.string),
    country: Nullable(t.string),
    postalCode: Nullable(t.string),
    street: Nullable(t.string),
    companyName: Nullable(t.string),
    city: Nullable(t.string),
});

export const ListManagerM = t.type({
    id: t.number,
    displayName: t.string,
    email: Nullable(t.string),
    firstName: Nullable(t.string),
    lastName: Nullable(t.string),
    salutation: Nullable(t.string),
});

export const ManagerM = t.intersection([
    ListManagerM,
    t.type({
        birthdate: Nullable(MomentDate),
        canSeeStatistics: t.boolean,
        canSu: t.boolean,
        city: Nullable(t.string),
        companyCity: Nullable(t.string),
        companyName: Nullable(t.string),
        companyNumber: Nullable(t.string),
        companyPostalCode: Nullable(t.string),
        companyStreet: Nullable(t.string),
        country: Nullable(t.string),
        fax: Nullable(t.string),
        hasAgroFinca: t.boolean,
        iban: Nullable(t.string),
        mobile2: Nullable(t.string),
        mobile: Nullable(t.string),
        numApartments: t.number,
        numFincas: t.number,
        postalCode: Nullable(t.string),
        street: Nullable(t.string),
        takePaymentManagement: t.boolean,
        telephone2: Nullable(t.string),
        telephone: Nullable(t.string),
        vatin: Nullable(t.string),
    }),
]);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ManagerC = t.TypeOf<typeof ManagerC>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ListManagerM = t.TypeOf<typeof ListManagerM>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ManagerM = t.TypeOf<typeof ManagerM>;
