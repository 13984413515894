import * as t from "io-ts";

export const Region = t.keyof({
    "sant-joan": null,
    petra: null,
    "vilafranca-de-bonany": null,
    marratxi: null,
    sencelles: null,
    "lloret-de-vistalegre": null,
    arta: null,
    "santa-margalida": null,
    manacor: null,
    capdepera: null,
    "porto-colom": null,
    "porto-cristo": null,
    "sant-llorenc-des-cardassar": null,
    "son-servera": null,
    llucmajor: null,
    santanyi: null,
    felanitx: null,
    campos: null,
});

export type RegionObject = { key: Region; label: string };
export type RegionGroup = { label: string; regions: RegionObject[] };

export const REGION_GROUPS: RegionGroup[] = [
    {
        label: "Inselmitte",
        regions: [
            { key: "sant-joan", label: "Sant Joan" },
            { key: "petra", label: "Petra" },
            { key: "vilafranca-de-bonany", label: "Vilafranca de Bonany" },
            { key: "marratxi", label: "Marratxi" },
            { key: "sencelles", label: "Sencelles" },
            { key: "lloret-de-vistalegre", label: "Lloret de Vistalegre" },
        ],
    },
    {
        label: "Norden",
        regions: [
            { key: "arta", label: "Arta" },
            { key: "santa-margalida", label: "Santa Margalida" },
        ],
    },
    {
        label: "Osten",
        regions: [{ key: "manacor", label: "Manacor" }],
    },
    {
        label: "Ostküste",
        regions: [
            { key: "capdepera", label: "Capdepera" },
            { key: "porto-colom", label: "Porto Colom" },
            { key: "porto-cristo", label: "Porto Cristo" },
            { key: "sant-llorenc-des-cardassar", label: "Sant Llorenc des Cardassar" },
            { key: "son-servera", label: "Son Servera" },
        ],
    },
    {
        label: "Süden",
        regions: [
            { key: "llucmajor", label: "Llucmajor" },
            { key: "santanyi", label: "Santanyi" },
            { key: "felanitx", label: "Felanitx" },
            { key: "campos", label: "Campos" },
        ],
    },
];

export const REGIONS: Region[] = REGION_GROUPS.reduce(
    (acc: Region[], cur) => [...acc, ...cur.regions.map((region) => region.key)],
    [],
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Region = t.TypeOf<typeof Region>;
