import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { Apartment } from "./Apartment";
import { ImageTuple } from "./Image";
import { Region } from "./Region";
import { FincaFeatures } from "./FincaFeatures";

export const Finca = t.type({
    id: t.number,
    name: t.string,
    apartments: t.array(Apartment),
    mainImage: Nullable(ImageTuple),
    isActive: t.boolean,

    poligono: Nullable(t.string),
    parcela: Nullable(t.string),
    tourismLicense: Nullable(t.string),
    cadastralReference: Nullable(t.string),
    region: Nullable(Region),
    features: FincaFeatures,
    bookingRulesPreset: t.keyof({
        automatic: null,
        manual: null,
        locked: null,
        none: null,
    }),
    wifiSsid: Nullable(t.string),
    wifiPassword: Nullable(t.string),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Finca = t.TypeOf<typeof Finca>;
