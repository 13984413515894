import * as t from "io-ts";

import { MomentDate } from "./Moment";
import { Dinero } from "./Dinero";
import { Nullable } from "./Nullable";

export const ApiResultManagerPriceRequest = t.record(
    t.number,
    t.array(
        t.type({
            start: MomentDate,
            end: MomentDate,
            value: Nullable(Dinero),
        }),
    ),
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ApiResultManagerPriceRequest = t.TypeOf<typeof ApiResultManagerPriceRequest>;
